<template>
  <v-navigation-drawer
    width="250"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div
            class="drawer-toggler px-3 cursor-pointer"
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
            </div>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list nav dense>
      <v-list-item-group>
        <v-list-item
          v-if="ruolo === 'Amministratore'"
          link
          to="/listUtenti"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon>fas fa-user text-blue</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Utenti</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          v-if="!ruolo"
          to="/login"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon>fas fa-user</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          v-if="ruolo"
          :to="
            ruolo === 'Amministratore'
              ? '/listProgetti'
              : '/listProgettiOperatore'
          "
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon>fas fa-clipboard-list text-red</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Progetti</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="ruolo === 'Amministratore'"
          link
          to="/presetPassi"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon>fas fa-suitcase-rolling text-yellow</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Preset Checklist</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="ruolo === 'Amministratore'"
          link
          to="/"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon>fas fa-chart-pie text-info</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Grafici</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <!--div class="border-bottom ma-3"></div>

      <template v-if="ruolo === 'Amministratore'">
        <h5
          class="
            text-uppercase text-caption
            ls-0
            font-weight-600
            p-0
            text-muted
            mx-3
            mb-4
            d-none-mini
            white-space-nowrap
          "
        >
          Altro
        </h5>

        <v-list-item-group>
          <v-list-item
            link
            to="/Componenti"
            class="pb-1 no-default-hover"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon>
              <v-icon>fas fa-cog</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Impostazioni</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template-->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mini: false,
      togglerActive: false,
      thirdLevelSimple: [
        "Third level menu",
        "Just another link",
        "One last link",
      ],
      selectedItem: undefined,
    };
  },
  computed: {
    ruolo() {
      return this.$store.state.ruolo;
    },
  },
  methods: {
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
  },
};
</script>
